@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;500;600&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400&family=Playfair+Display:wght@400;500;600&display=swap");

:root {
  --font-base: "Playfair Display", serif;
  --font-secondary: "Montserrat", sans-serif;
  --primary-color: #edf2f8;
  --secondary-color: #313bac;
  --black-color: #030303;
  --lightGray-color: #e4e4e4;
  --gray-color: #6b7688;
  --brown-color: #46364a;
  --white-color: #ffffff;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}
